@import "./styles/colors.scss";
@import "../public/assets/style/variables.scss";

.container {
    // width: 85%;
    padding: 0 100px;
    margin: auto;
    max-width: 1600px;

    @media screen and (max-width: 772px) {
        padding: 0 20px;
    }
}

.ant-layout-header {
    background-color: #0b0b0b17;
    padding: 5px 40px;
}

.ant-layout-content {
    // min-height: calc(100vh - 80px);
    background-color: $background_color;
    overflow: hidden;
}

.ant-input-status-error {
    background-color: transparent !important;
    box-shadow: 0px 0px 0px 0px transparent !important;
}

// .ant-input:not(.global-editor *, .form *, .ant-drawer-body *),
// .ant-select:not(.ant-select-customize-input) .ant-select-selector:not(.global-editor * .ant-drawer-body *) {
//     background: transparent;
//     // border-top-color: transparent !important;
//     // border-left-color: transparent !important;
//     // border-right-color: transparent !important;
//     // border-bottom-color: white !important;
//     border-color: white !important;
//     box-shadow: 0px 0px 0px 0px transparent;
//     color: white;

//     &:active,
//     &:focus {
//         border-top-color: transparent !important;
//         border-left-color: transparent !important;
//         border-right-color: transparent !important;
//         border-bottom-color: white !important;
//         box-shadow: 0px 0px 0px 0px transparent;
//     }
// }

.ant-select-selector {
    box-shadow: 0px 0px 0px 0px transparent !important;
}

.ant-select-arrow {
    color: white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $active_color !important;
}

.ant-tabs-tab:first-child {
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.ant-tabs-tab-btn {
    &:hover {
        color: $hover_color !important;
    }
}

.ant-tabs-tab {
    &:hover {
        color: $text-color !important;
    }
}

.ant-tabs-ink-bar {
    background: $active_color !important;
}

.ant-form-item-explain-error {
    color: #ffa2a3 !important;
}

p {
    // color: white ;
}

.modal-component {
    .ant-modal-content {
        border-radius: 10px;
    }

    h3 {
        font-size: 30px;
        color: $app;
    }

    h1 {
        font-size: 40px;
        color: $app;
    }

    img {
        width: 120px;
    }

    p {
        margin-top: 30px;
        font-size: 20px;
        // color: $app !important;
    }
}

.mac-validation {
    background: transparent !important;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: white !important;
    box-shadow: 0px 0px 0px 0px transparent;
    color: white;

    &:active,
    &:focus {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: white !important;
        box-shadow: 0px 0px 0px 0px transparent;
    }

    &.app-color {
        border-bottom-color: $app !important;
    }
}

.ant-input-affix-wrapper-status-error {
    box-shadow: none !important;
}

.ant-form-item-explain-success {
    color: $active_color !important;
}

// .m3u8-file-input {
//     border-bottom: 1px solid white !important;
//     position: relative;
//     padding: 5px 0;
//     margin-top: 5px;
//     border:1px solid white !important;

//     input {
//         opacity: 0;
//         top: 0;
//         position: absolute;
//     }
// }

.page-wrapper {
    padding-top: 80px;
    padding-bottom: 35px;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PhoneInputInput {
    background: transparent;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: white !important;
    box-shadow: 0px 0px 0px 0px transparent;
    color: white;
    border-bottom-width: 1px;
    outline: none;

    &:active,
    &:focus {
        border-top-color: transparent !important;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: white !important;
        box-shadow: 0px 0px 0px 0px transparent;
    }
}

.loading-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
}

.toggle-section.ant-switch-checked {
    background-color: $primary-color;
}

.global-editor .ant-typography {
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    background-color: #fff;
    padding: 10px 15px;
    margin-bottom: 0;
}

.ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 250px;
}

.d-flex {
    display: flex;
}

.direction-col {
    flex-direction: column;
}

.text-primary {
    color: $primary-dark !important;
}

.mt-sm {
    margin-top: 5px;
}

.mt-md {
    margin-top: 10px;
}

.mb-md {
    margin-bottom: 10px;
}

.mb-sm {
    margin-bottom: 5px;
}

.align-center {
    align-items: center;
}

.switch-activation {
    margin-right: 15px;
}

.textarea-component {
    resize: none;

    color: black !important;
}

.ant-input-number-out-of-range input {
    color: $primary-dark;
}

@media only screen and (min-width: 360px) and (max-width: 896px) {
    .ant-layout-header {
        height: 60px;
        padding: 5px 20px !important;
    }
}

@media screen and (max-width: 772px) {
    .ant-layout-header {
        padding: 5px 5px;
    }

    .page-component {
        justify-content: flex-start !important;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s !important;
    -webkit-box-shadow: 0 0 0 1000px #13495700 inset !important;
    -webkit-text-fill-color: white !important;
}

.contact-form input:-webkit-autofill,
.contact-form input:-webkit-autofill:active {
    -webkit-text-fill-color: rgba(7, 64, 76, 0.6) !important;
}

.ant-input-affix-wrapper {
    background-color: #0a4f5f00 !important;
}

a.active {
    color: $active_color !important;
}

.ant-layout-footer {
    padding: 0 !important;
}

.active-page-index {
    border-bottom: 2px solid $primary-color;
}

.contact-form {
    padding: 82px 50px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;

    .ant-input {
        // width: 370px;
        border: 1px solid rgba(7, 64, 76, 0.3) !important;
        background-color: #fff;
        border-radius: 5px;
        padding: 15px 15px 15px 25px;
        font-size: 15px;
        color: rgba(7, 64, 76, 0.6) !important;

        &:focus {
            border: 1px solid rgba(7, 64, 76, 0.3) !important;
        }
    }
}

.full-screen-loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;

    backdrop-filter: blur(15px);
    background: rgba(0, 0, 0, 0.897);
    background-color: hsla(0, 0%, 0%, 0.871);
    display: flex;
    justify-content: center;
    padding: 30px;
}

.ant-layout {
    min-height: 100vh !important;
}

.title-style {
    color: $title_color;
}