:root {
    --active: #FFC107;
    --background-color: black;
    --button-text-color: grey;
    --hover-color: #05822b;
    --text-color: white;
    --logo-size: 40px;
    --navbar-background-color: rgb(2, 2, 2);
    --navbar-text-color: white;
    --title-color: grey;
}

// a
$active_color: var(--active);

// b
$background_color: var(--background-color);
$buttons_text_color: var(--button-text-color);

// c
// d
// e
// f
// g
// h
$hover_color: var(--hover-color);

// i
// j
// k
// l
$logo_size: var(--logo-size);

// m
// n
$navbar_text_color: var(--navbar-text-color);
$navbar_background_color: var(--navbar-background-color);
// o
// p
// q
// r
// s
// t
$text-color: var(--text-color);
$title_color: var(--title-color);

// u
// v
// w
// x
// y
// z

$card-text-color: #fff;
$footer-background-color: #ffc107;
$btn-text-color: #FFC107;
$border-radius: 20px;
$app: #FFC107;
$primary-color: #FFC107;
$primary-dark: #07404c;

$secondary-dark: #0000005e;
$danger-color: #ffa2a3 ;
$success-color: #28a745;